<template>
  <div class="right">
    <span class="spen">发票申请</span>

    <div class="bottom">
      <div class="tou">普通电子发票信息</div>
      <div class="from">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item :label="$t('label.Order_number2')" label-width="100px">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('label.Invoice_type')" label-width="100px">
            <el-select v-model="form.region" :placeholder="$t('label.Invoice_type')">
              <el-option :label="$t('label.Electronic_ordinary_invoice')" value="shanghai"></el-option>
              <el-option label="商品类别" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('label.Invoice_content')" label-width="100px">
            <el-select v-model="form.region" :placeholder="$t('label.Header_type')">
              <el-option label="商品明细" value="shanghai"></el-option>
              <el-option label="商品类别" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('label.Header_type')" label-width="100px">
            <el-select v-model="form.region" placeholder="活动区域">
              <el-option :label="$t('label.personage')" value="shanghai"></el-option>
              <el-option label="公司" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('label.Invoice_title')" label-width="100px">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="收票人手机" label-width="100px">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-form>
        <div class="button">
          <div class="tijiao">提交</div>
          <div class="quxiao">{{ $t('button.Cancel') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.right {
  padding: 34px 20px 0 27px;
  background: #fff;
  flex: 1;
  margin-left: 15px;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }

  .bottom {
    width: 808px;
    height: 617px;
    margin-top: 46px;
    margin-left: 80px;
    .tou {
      width: 100%;
      height: 56px;
      background: #e0e8fd;
      line-height: 56px;
      font-size: 18px;
      color: #323335;
      font-weight: 600;
      text-align: center;
    }
    .from {
      border: 1px solid #d6d6d6;
      width: 756px;
      padding: 20px 50px 70px 0;
      .button {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        font-size: 15px;
        .tijiao {
          width: 74px;
          height: 40px;
          background: #2659f2;
          color: #fff;
          text-align: center;
          line-height: 40px;
          border-radius: 5px;
        }
        .quxiao {
          width: 74px;
          height: 40px;
          border: 1px solid #e5e3e4;
          color: #555;
          text-align: center;
          line-height: 40px;
          border-radius: 5px;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
